<template>
  <b-card title="">
    <b-tabs>
      <b-tab  @click="$router.push('/add-specific-price')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New Specific Prices</span>
        </template>

      <!-- <AddSpecificPrice @add-price="getData"></AddSpecificPrice> -->
      </b-tab>
      <b-tab @click="$router.push('/specific-price')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Specific Prices</span>
        </template>
       
       <!-- <ViewSpecificPrice ref="allData"></ViewSpecificPrice> -->
      </b-tab>
       
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
    <AddSpecificPrice v-if="route.name === 'index-specific-price' || route.name === 'add-specific-price'  "  />
    <router-view  v-else>
        <component :is="Component" />
      </router-view>
</router-view>
  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'


import { ref } from "@vue/composition-api";
import AddSpecificPrice from './AddSpecificPrice.vue';
import ViewSpecificPrice from './ViewSpecificPrice.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    AddSpecificPrice,
    ViewSpecificPrice
},
setup() {
    const allData=ref()
  
    const getData=()=>{
    
      // allData.value.refetchData()
           

    }
   return{
    getData,
    allData,
   

   }
   
   }
}
</script>
