<template>
  <div class="table-list">
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        </b-row>
      </div>
    

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="categoriesList"
        striped
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Status -->

         
        <template #cell(discount)="data">
          
          {{data.item.discount}} %
        </template>
       
        <template #cell(actions)="data">
          
          <!-- <a
            :to="{ name: 'update-specife-price', params: { id: data.item.id } }"
            style="margin: 0px 12px">
            <feather-icon icon="EditIcon" />
          </a> -->
          <a role="button" @click="DeleteItem(data.item.id)">
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, watch, computed, reactive } from "@vue/composition-api";
import Vue from "vue";

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,

    vSelect,
  },
  setup() {
    const categoriesList = ref([]);
    const perPageOptions = [10, 25, 50, 100, 200];
    const perPage = ref(10);
    const totalCategories = ref(0);
    const currentPage = ref(1);
    const refCourseListTable = ref("");

    const tableColumns = [
     
      { key: "category", label: "Category", sortable: true },
      { key: "course", label: " Course", sortable: true },
     
      { key: "email", label: "Email", sortable: true },
      { key: "users", label: "Users", sortable: true },
        { key: "expire_date", label: "Expire Date", sortable: true },
      { key: "code", label: "Code", sortable: true },
      { key: "companyName", label: "CompanyName", sortable: true },
      { key: "discount", label: "Discount", sortable: true },

    
    ];

    watch([currentPage, perPage], () => {
      getCategories();
    });
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      };
    });
    const meta = reactive({});
    const getAll = () => {
      store
        .dispatch("setting/allCodeGenertor", {
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
          console.log("allCodeGenertor", response.data);
          categoriesList.value = response.data.data.data;
          totalCategories.value = response.data.data.total;
          console.log("totalCategories", totalCategories);
        });
    };
    getAll();
    const DeleteItem = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("blog/Deletecertificate", id).then((response) => {
            GetAllCourses();
            Vue.swal({
              title: "Certificate Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };
    return {
      tableColumns,
      categoriesList,
      DeleteItem,
      meta,
      getAll,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.position-relative.table-responsive td {
  padding: 0px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
